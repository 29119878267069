import {
  LogoImage,
  HeaderContainer,
} from "components/layout/Header/Header.styles";
import { Typography } from "@mui/material";
import HeaderLogo from "assets/header/header.svg";
import { TemporaryDrawerMenu } from "components/layout";
import { useState } from "react";
import { HamburgerButton } from "components/buttons";

export interface HeaderProps {
  userName: string | null;
  // We take this in as a prop, because there are other layout changes
  // that will occur at small screen sizes, so we should use the parent
  // layout component to get the screen size.
  isSmallScreenSize?: boolean;
}

export function Header({ userName, isSmallScreenSize }: HeaderProps) {
  const [open, setOpen] = useState<boolean>(false);

  const userNameTextComponent = (
    <Typography fontWeight="bold">Hi, {userName}!</Typography>
  );

  return (
    <>
      <HeaderContainer component="header">
        <LogoImage alt="Yardzen Pro Network Logo" src={HeaderLogo} />
        {isSmallScreenSize ? (
          <HamburgerButton onClick={() => setOpen(!open)} />
        ) : (
          userName && userNameTextComponent
        )}
      </HeaderContainer>
      {isSmallScreenSize && (
        <TemporaryDrawerMenu
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
        >
          {userName && userNameTextComponent}
        </TemporaryDrawerMenu>
      )}
    </>
  );
}
