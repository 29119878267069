import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { PageLayout } from "components/layout";
import { theme } from "./utils/styles/theme";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "routes";
import { useUpdateSessionStateOnAuthChange } from "utils/hooks/useUpdateSessionStateOnAuthChange";
import { selectContractor } from "state/slices/contractor/contractorSlice";
import { useSelector } from "react-redux";

function App() {
  useUpdateSessionStateOnAuthChange();

  const contractor = useSelector(selectContractor);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <BrowserRouter>
          <PageLayout userName={contractor ? contractor.businessName : null}>
            <AppRoutes />
          </PageLayout>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
