export enum ContractorAbilities {
  "Woodwork" = "Woodwork",
  "Metal Work" = "Metal Work",
  "Pergolas - Composite, Metal, Kits" = "Pergolas - Composite, Metal, Kits",
  "Decking - Composite" = "Decking - Composite",
  "Lighting - Low Voltage" = "Lighting - Low Voltage",
  "Masonry" = "Masonry",
  "Turf" = "Turf",
  "Pavers" = "Pavers",
  "Concrete" = "Concrete",
  "Outdoor Kitchens" = "Outdoor Kitchens",
  "Outdoor Showers" = "Outdoor Showers",
  "Water Features/Ponds" = "Water Features/Ponds",
  "CA. Rooms" = "CA Rooms",
  "Pools/Spas - Custom" = "Pools/Spas - Custom",
  "Pools/Spas - Pre Fab" = "Pools/Spas - Pre Fab",
  "Sheds/ADUs" = "Sheds/ADUs",
  "Botanical only" = "Botanical Only",
  "Exterior" = "Exterior",
}

export interface Contractor {
  id: number;
  firestoreUuid: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  deletedBy: string | null;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  streetLineOne: string | null;
  streetLineTwo: string | null;
  city: string;
  state: string;
  zip: string | null;
  country: string | null;
  phone: string;
  email: string;
  website: string | null;
  pointOfContactName: string | null;
  pointOfContactEmail: string | null;
  pointOfContactPhone: string | null;
  notes: string | null;
  preferred: boolean;
  isActive: boolean;
  abilities: ContractorAbilities[];
  licenseNumber: string | null;
  coordinates: {
    lat: number;
    lon: number;
  };
  bsatScore: number;
}
