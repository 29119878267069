import React from "react";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Route, Routes } from "react-router";

const ProjectRouter = React.lazy(() =>
  import("pages/project/ProjectRouter").then((module) => ({
    default: module.ProjectRouter,
  }))
);

const DevLoginPage = React.lazy(() =>
  import("pages/login/DevLoginPage").then((mod) => ({
    default: mod.DevLoginPage,
  }))
);

const ErrorPage = React.lazy(() =>
  import("pages/error/ErrorPage").then((mod) => ({ default: mod.ErrorPage }))
);

const FourOhFour = React.lazy(() =>
  import("pages/404").then((mod) => ({ default: mod.FourOhFour }))
);

export interface AppRoutesProps {}

export const AppRoutes: React.FunctionComponent<AppRoutesProps> = () => {
  const development = process.env.NODE_ENV === "development";

  return (
    <Routes>
      <Route
        path="/project/*"
        element={
          <React.Suspense fallback={<LoadingSpinner />}>
            <ProjectRouter />
          </React.Suspense>
        }
      />
      {development && (
        <Route
          path="/login/dev"
          element={
            <React.Suspense fallback={<LoadingSpinner />}>
              <DevLoginPage />
            </React.Suspense>
          }
        />
      )}
      <Route
        path="/error/*"
        element={
          <React.Suspense fallback={<LoadingSpinner />}>
            <ErrorPage />
          </React.Suspense>
        }
      />
      <Route
        element={
          <React.Suspense fallback={<LoadingSpinner />}>
            <FourOhFour />
          </React.Suspense>
        }
        path="/404"
      />
    </Routes>
  );
};
