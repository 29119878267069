import { Contractor, ContractorAbilities } from "models/Contractor";
import { faker } from "@faker-js/faker";

let _abilities: ContractorAbilities[] = [];

/**
 * Picks a random value from the ContractorAbilities enum
 * @returns A random contractor Ability
 */
function randomContractorAbility(): ContractorAbilities {
  if (!_abilities) {
    _abilities = [];

    for (const value in ContractorAbilities) {
      _abilities.push(value as ContractorAbilities);
    }
  }

  return _abilities[Math.ceil(Math.random() * _abilities.length - 1)];
}

/**
 * Returns a specified number of contractorAbilities in an array
 * @param n desired returned array size
 * @returns an array of ContractorAbilities values
 */
function randomContractorAbilities(n?: number): ContractorAbilities[] {
  const abilities: ContractorAbilities[] = [];
  n = typeof n === "undefined" ? Math.min(1, Math.ceil(Math.random() * 10)) : n;
  for (let i = 0; i < n; ++i) {
    abilities.push(randomContractorAbility());
  }

  return abilities;
}

/**
 * mockContractor assembles and returns a `Contractor` with random data
 * @param overrideValues This object will be spread onto the resulting mock, replacing existing values
 * @returns a Contractor with random realistic data
 */
export function mockContractor(
  overrideValues?: Partial<Contractor>
): Contractor {
  const randomCoords = faker.address.nearbyGPSCoordinate().map(parseFloat);
  return {
    abilities: randomContractorAbilities(),
    bsatScore: faker.datatype.number({ min: 0, max: 10 }),
    businessName: faker.company.companyName(),
    city: faker.address.city(),
    country: "United States of America",
    createdAt: faker.date.past().toISOString(),
    updatedAt: new Date().toISOString(),
    deletedAt: null,
    deletedBy: null,
    email: faker.internet.email(),
    firestoreUuid: faker.datatype.uuid(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    id: faker.unique(faker.datatype.number),
    isActive: faker.datatype.boolean(),
    licenseNumber: faker.random.alphaNumeric(10),
    notes: faker.lorem.lines(faker.datatype.number({ min: 1, max: 5 })),
    phone: faker.phone.phoneNumber(),
    pointOfContactEmail: faker.internet.email(),
    pointOfContactName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    pointOfContactPhone: faker.phone.phoneNumber(),
    preferred: faker.datatype.boolean(),
    state: faker.address.stateAbbr(),
    streetLineOne: faker.address.streetAddress(),
    streetLineTwo: null,
    coordinates: {
      lat: randomCoords[0],
      lon: randomCoords[1],
    },
    website: faker.internet.url(),
    zip: faker.address.zipCode(),
    ...overrideValues,
  };
}

/**
 * MockContractors will generate a specified amount of mocked Contractors
 * @param n Amount of Contractors to mock
 * @param mockContractorArgs Arguments to be passed to mockContractors
 * @returns An array of Contractors with random data
 */
export function mockContractors(
  n: number,
  ...mockContractorArgs: Parameters<typeof mockContractor>
): Contractor[] {
  if (n < 0) {
    throw new Error("Cannot mock less than 0 contractors");
  }

  return new Array(n).fill(0).map(() => mockContractor(...mockContractorArgs));
}

export const contractors = mockContractors(3);
