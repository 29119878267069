import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps,
  styled,
} from "@mui/material";
import React, { FC } from "react";

export interface LoadingSpinnerProps extends BoxProps {
  progressProps?: CircularProgressProps;
}

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: `${theme.spacing(1)}px`,
}));

/**
 * A general use spinning progress component.
 */
export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  progressProps,
  ...containerProps
}) => {
  return (
    <Container {...containerProps}>
      <CircularProgress />;
    </Container>
  );
};
